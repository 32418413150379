import React, { useEffect, useState } from 'react';
import '../App.css';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCirclePlay} from '@fortawesome/free-solid-svg-icons';
import { FaLink } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaDiscord, FaWhatsapp, FaInstagram, FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import Backtotop from '../Components/Backtotop';
import axios from 'axios';

const Why = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [registerData, setRegisterData] = useState(
        {
            email1: '',
            email2: '',
            email3: '',
            email4: '',
            email5: '',
            email6: '',
            email7: '',
            email8: '',
            email9: '',
            email10: '',
            email11: '',
        },
    );



    const handleInput = (e) => {
        if (e.target.getAttribute('source') === 'Arch&IntDes') {
            setRegisterData(registerData => ({ ...registerData, email1: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'EventPlanners') {
            setRegisterData(registerData => ({ ...registerData, email2: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'Hotels') {
            setRegisterData(registerData => ({ ...registerData, email3: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'ThemeParks') {
            setRegisterData(registerData => ({ ...registerData, email4: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'RetailShowrooms') {
            setRegisterData(registerData => ({ ...registerData, email5: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'RealEstateDevelopment') {
            setRegisterData(registerData => ({ ...registerData, email6: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'ExhibitionOrganizers') {
            setRegisterData(registerData => ({ ...registerData, email7: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'WeddingOrganizers') {
            setRegisterData(registerData => ({ ...registerData, email8: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'OfficeSpaces') {
            setRegisterData(registerData => ({ ...registerData, email9: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'OfficeProducts') {
            setRegisterData(registerData => ({ ...registerData, email10: e.target.value }));
        }
        if (e.target.getAttribute('source') === 'Newsletter') {
            setRegisterData(registerData => ({ ...registerData, email11: e.target.value }));
        }
    }


    const handleSubmission = (e) => {
        console.log();
        if (e.target.previousSibling.getAttribute('source') === 'Arch&IntDes') {
            handleApi(registerData.email1, 'Arch&IntDes');
        }
        if (e.target.previousSibling.getAttribute('source') === 'EventPlanners') {
            handleApi(registerData.email2, 'EventPlanners');
        }
        if (e.target.previousSibling.getAttribute('source') === 'Hotels') {
            handleApi(registerData.email3, 'Hotels');
        }
        if (e.target.previousSibling.getAttribute('source') === 'ThemeParks') {
            handleApi(registerData.email4, 'ThemeParks');
        }
        if (e.target.previousSibling.getAttribute('source') === 'RetailShowrooms') {
            handleApi(registerData.email5, 'RetailShowrooms');
        }
        if (e.target.previousSibling.getAttribute('source') === 'RealEstateDevelopment') {
            handleApi(registerData.email6, 'RealEstateDevelopment');
        }
        if (e.target.previousSibling.getAttribute('source') === 'ExhibitionOrganizers') {
            handleApi(registerData.email7, 'ExhibitionOrganizers');
        }
        if (e.target.previousSibling.getAttribute('source') === 'WeddingOrganizers') {
            handleApi(registerData.email8, 'WeddingOrganizers');
        }
        if (e.target.previousSibling.getAttribute('source') === 'OfficeSpaces') {
            handleApi(registerData.email9, 'OfficeSpaces');
        }
        if (e.target.previousSibling.getAttribute('source') === 'OfficeProducts') {
            handleApi(registerData.email10, 'OfficeProducts');
        }
        if (e.target.previousSibling.getAttribute('source') === 'Newsletter') {
            handleApi(registerData.email11, 'Newsletter');
        }
    }

    const enquiryurl = `https://admin.interioxr.com`;

    const handleApi = async (email, tab) => {
        console.log(email, tab);
        await axios.post(`${enquiryurl || 'http://localhost:3001'}/api/v1/enquiry`, {
            "email": email,
            "source": `interiorverse.interioxr.com:${tab}`
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(result => {
                alert('Thank you for contacting us. We will get back to you soon.');
                console.log(result)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <section className="why-sec-1 sec-color py-4 mt-6 mb-5 over" id='bg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center ">
                        <div className="col-md-2">
                            <img src={`assets/img/iXr-1.webp`} alt="ixr-2" className='I1 hidden' />
                        </div>
                        <div className="col-md-10 text-end">
                            <h1 className="text-white fs-80 fw-bolder wt mb-4 t-shadow ms-auto" id='text' style={{ "zIndex": "2", 'position': "relative" }}>A True 21st Century Millenial-run Boomer-backed & Gen-Z Inspired Design Company.</h1>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row align-items-center mT" style={{ "marginTop": "-70px" }}>
                            <div className="col-md-10">
                                <Link to='/contact'>
                                    <motion.span whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-3">Contact Us <FontAwesomeIcon icon={faArrowRight} /></motion.span>
                                </Link>
                                <p className='text-white wt' style={{ "zIndex": "2", 'position': "relative" }} id='text'>Experience fully immersive VR walkthroughs of your dream interiors before they become a reality. Our cutting-edge VR app takes you on a hyper-realistic journey through your designs, enabling you to visualize every detail in 3D. </p>
                            </div>
                            <div className="col-md-2">
                                <img src={`assets/img/iXr.webp`} alt="ixr" className='I1' style={{ "marginTop": "-292px", "marginLeft": "187px", "zIndex": "1", 'position': "relative" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="why-sec-2 sec-color py-4 over" id='bg'>
                <div className="container text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className="text-white fw-bolder fs-70 t-shadow mb-2 index" id='text'>See How interiorVerse Aides Industry.</h1>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/1.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    #interiorVerse For Arch & IntDes
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Arch & IntDes/1.webp`} alt="Arch & IntDes-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Arch & IntDes/2.webp`} alt="Arch & IntDes-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email1} source='Arch&IntDes' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/2.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    #interiorVerse For Event Planners
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse py-4" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Event/1.webp`} alt="event-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Event/2.webp`} alt="event-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email2} source='EventPlanners' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/3.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    #interiorVerse For Hotels
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Hotels/1.webp`} alt="Hotels-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Hotels/2.webp`} alt="Hotels-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email3} source='Hotels' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/4.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    #interiorVerse For Theme Parks
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Theme/1.webp`} alt="Theme-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Theme/2.webp`} alt="Theme-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email4} source='ThemeParks' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/5.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    #interiorVerse For Retail Showrooms
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Retail/1.webp`} alt="Retail-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Retail/2.webp`} alt="Retail-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email5} source='RetailShowrooms' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/6.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                    #interiorVerse For Real Estate Development
                                </button>
                            </h2>
                            <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Real/1.webp`} alt="Real-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Real/2.webp`} alt="Real-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email6} source='RealEstateDevelopment' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/7.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    #interiorVerse For Exhibition Organizers
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/1.webp`} alt="Exhibition-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/2.webp`} alt="Exhibition-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email7} source='ExhibitionOrganizers' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/8.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    #interiorVerse For Wedding Organizers
                                </button>
                            </h2>
                            <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/1.webp`} alt="Exhibition-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/2.webp`} alt="Exhibition-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email8} source='WeddingOrganizers' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/9.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    #interiorVerse For Office Spaces
                                </button>
                            </h2>
                            <div id="flush-collapseNine" class="accordion-collapse collapse py-4" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/office/1.webp`} alt="office-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/office/2.webp`} alt="office-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email9} source='OfficeSpaces' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/10.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    #interiorVerse For Office Products
                                </button>
                            </h2>
                            <div id="flush-collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Products/1.webp`} alt="Products-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Products/2.webp`} alt="Products-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" value={registerData.email10} source='OfficeProducts' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-sec-2 sec-color py-5 my-5 over' id='bgg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className="text-white fs-85 fw-bolder w-75 mb-4 t-shadow ms-auto text-end" >Subscribe To Our Updates</h1>
                    <div className="row align-items-center mb-3" style={{ "marginTop": "-55px" }}>
                        <div className="col-md-7" >
                            <video autoPlay loop muted className='rounded-5 w-100'>
                                <source src={`assets/img/Subs.mp4`} type="video/mp4" />
                            </video>
                        </div>
                        <div className="col-md-5" >
                            <h3 className='text-white pb-2'>Stay in the loop with latest developments as we welcome the future, together.</h3>
                            <Link to='/' className="text-white">
                                <p><FontAwesomeIcon icon={faCirclePlay} /> View Post Newsletters</p>
                            </Link>
                            <div className="formGroup news">
                                <input type="email" value={registerData.email11} source='Newsletter' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Sign Up! <FaLink /> </motion.button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Backtotop />

            <Link to='https://www.facebook.com/interioxr'><button className='btn face fs-3 text-primary pb-3'><FaFacebook /></button></Link>
            <Link to='https://api.whatsapp.com/send/?phone=919560013324&text&type=phone_number&app_absent=0' target='_blank'><button className='btn whats fs-4 text-success pb-3'><FaWhatsapp /></button></Link>
            <Link to='https://www.linkedin.com/company/interioxr/'><button className='btn link fs-3 text-primary pb-3'><FaLinkedin /></button></Link>
            <Link to='https://www.instagram.com/interioxr/' target='_blank'><button className='btn insta fs-3 text-danger pb-3'><FaInstagram /></button></Link>
            <Link to='https://twitter.com/InterioXr'><button className='btn twit fs-3 text-primary pb-3'><FaTwitter /></button></Link>
            <Link to='https://discord.com/invite/JJNZr6Hkdh' target='_blank'><button className='btn discord fs-3 text-white pb-3'><FaDiscord /></button></Link>
        </>
    )
}

export default Why
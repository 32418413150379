import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../App.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faRocket, faLink, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Backtotop from '../Components/Backtotop'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { motion } from "framer-motion";
import Marquee from 'react-fast-marquee';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import TestCard from '../Components/TestCard';
import { FaDiscord, FaWhatsapp, FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaLink } from "react-icons/fa";

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [text] = useTypewriter({
        words: ['Spaces', 'Interiors', 'Events', 'Hotel Spaces', 'Retail Showroom', 'Office Spaces'],
        loop: Infinity,
    })

    const [registerData, setRegisterData] = useState(
        {   email1: '',
            email2: '',
            email3: '',
            email4: '',
            email5: '',
            email6: '',
        },
    );
    
    

    const handleInput = (e) => {
        if(e.target.getAttribute('source') === 'Interiors'){
            setRegisterData(registerData =>({ ...registerData, email1 : e.target.value}));
        }
        if(e.target.getAttribute('source') === 'Spatial'){
            setRegisterData(registerData =>({ ...registerData, email2 : e.target.value}));
        }
        if(e.target.getAttribute('source') === 'Design'){
            setRegisterData(registerData =>({ ...registerData, email3 : e.target.value}));
        }
        if(e.target.getAttribute('source') === 'Research'){
            setRegisterData(registerData =>({ ...registerData, email4 : e.target.value}));
        }
        if(e.target.getAttribute('source') === 'Development'){
            setRegisterData(registerData =>({ ...registerData, email5 : e.target.value}));
        }
        if(e.target.getAttribute('source') === 'Newsletter'){
            setRegisterData(registerData =>({ ...registerData, email6 : e.target.value}));
        }
    }

    
    const handleSubmission = (e) =>{
        console.log();
        if(e.target.previousSibling.getAttribute('source') === 'Interiors'){
            handleApi(registerData.email1,'Interiors');
        }
        if(e.target.previousSibling.getAttribute('source') === 'Spatial'){
            handleApi(registerData.email2,'Spatial');
        }
        if(e.target.previousSibling.getAttribute('source') === 'Design'){
            handleApi(registerData.email3,'Design');
        }
        if(e.target.previousSibling.getAttribute('source') === 'Research'){
            handleApi(registerData.email4,'Research');
        }
        if(e.target.previousSibling.getAttribute('source') === 'Development'){
            handleApi(registerData.email5,'Development');
        }
        if(e.target.previousSibling.getAttribute('source') === 'Newsletter'){
            handleApi(registerData.email5,'Newsletter');
        }
    }

    const enquiryurl = `https://admin.interioxr.com`;

    const handleApi = async (email,tab) => {
        console.log(email, tab);
        await axios.post(`${enquiryurl || 'http://localhost:3001'}/api/v1/enquiry`, {
            "email": email,
            "source": `interiorverse.interioxr.com:${tab}`
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(result => {
                alert('Thank you for contacting us. We will get back to you soon.');
                console.log(result)
            })
            .catch(err => {
                console.log(err)
            })
    }


    return (
        <>
            <section className="home-sec-1 py-5 sec-color mt-6 mb-5 over" id='bg'>
                <iframe
                    title="Background Video"
                    src="https://my.spline.design/shapesanimationcopy-bf9928f80394eada2f5a128287d2e259/"
                    frameborder="0"
                    allowfullscreen
                ></iframe>

                <div className="content-overlay">
                    <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                        <h1 className="text-white fw-bolder t-shadow mb-5 fs-40 index w-50 ms-auto te">Step into Your Dream <br /> <span>{text}</span><span className='text-white'><Cursor /></span></h1>
                        <div className="row align-items-center">
                            <div className="col-md-6 offset-md-6 ">
                                <Link to='https://interiorverse.interioxr.com/webapp' target='_blank'>
                                    <motion.p whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-5 fs-5" >Enter InteriorVerse <FontAwesomeIcon icon={faRocket} /></motion.p>
                                </Link>
                                <p className="jt text-white">Unlock the Marvels of VR Design with interiorVerse’s Alpha Launch.
                                    As you assemble your
                                    imagination and take the leap into interiorVerse, where you can immerse yourself in
                                    stunningly lifelike visualizations of your dream interiors before they become a reality!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-sec-6 sec-color py-5 my-5 over' id='bg'>
                <div className="container text-center " data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <Marquee direction="right" speed={100} delay={5}>
                        <div className="image_wrapper">
                            <img src={`assets/img/Interior/1.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Interior/2.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Interior/3.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Interior/4.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Interior/5.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Interior/6.webp`} alt="" className='w-100' />
                        </div>
                    </Marquee>
                    <h1 className="text-white fw-bold fs-85 t-shadow mt-4 mb-0" id='text'>Interiors</h1>
                    <hr className="back-blue w-50 rounded-1 my-3 m-auto" style={{ "height": "6px" }} />
                    <p className='w m-auto text-white mb-4' id='text'>
                        Elevate your surroundings with our immersive interior design expertise. Immerse yourself in a curated collection of styles – from contemporary chic to timeless elegance – and let our media solutions redefine the art of living.
                    </p>
                    <div className="formGroup news m-auto">
                        <input type="email" value={registerData.email1} source='Interiors' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                        <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                    </div>
                </div>
            </section >

            <section className='home-sec-7 sec-color py-5 my-5 over' id='bg'>
                <div className="container text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <Marquee direction="left" speed={100} delay={5}>
                        <div className="image_wrapper">
                            <img src={`assets/img/Spatial/1.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Spatial/2.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Spatial/3.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Spatial/4.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Spatial/5.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Spatial/6.webp`} alt="" className='w-100' />
                        </div>
                    </Marquee>
                    <h1 className="text-white fw-bold fs-85 t-shadow mt-4 mb-0" id='text'>Spatial</h1>
                    <hr className="back-blue w-50 rounded-1 my-3 m-auto" style={{ "height": "6px" }} />
                    <p className='w m-auto text-white mb-4' id='text'>
                        At the forefront of urban evolution, our immersive media company crafts spatial designs that transcend boundaries. Discover a new paradigm where innovation meets aesthetics, redefining the very fabric of urban living.
                    </p>
                    <div className="formGroup news m-auto">
                        <input type="email" value={registerData.email2} source='Spatial' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                        <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                    </div>
                </div>
            </section >

            <section className='home-sec-8 py-5 sec-color my-5 over' id='bgg'>
                <div className="container tecenter" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-3">
                            <h1 className='text-white t-shadow fs-80 fw-bolder '>Design</h1>
                            <hr className="back-blue w-50 rounded-1 my-4 m-to" style={{ "height": "6px" }} />
                            <p className='text-white jt wt mb-4 para'>Design is at the heart of our operations at interiorVerse, see more about how our interior designers sculpt your dream space.</p>
                            <div className="formGroup news">
                                <input type="email" value={registerData.email3} source='Design' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <video autoPlay loop muted className='rounded-5 w-100'>
                                <source src={`assets/img/Design.mp4`} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section> 

            <section className='home-sec-9 py-5 sec-color my-5 over' id='bgg'>
                <div className="container text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <Marquee direction="left" speed={100} delay={5}>
                        <div className="image_wrapper">
                            <img src={`assets/img/Research/1.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Research/2.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Research/3.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Research/4.webp`} alt="" className='w-100' />
                        </div>
                    </Marquee>
                    <h1 className="text-white fw-bold fs-85 t-shadow mt-4 mb-0">Research</h1>
                    <hr className="back-blue w-50 rounded-1 my-3 m-auto" style={{ "height": "6px" }} />
                    <p className='w m-auto text-white mb-4' >
                        Unlock the potential of informed creativity. Our immersive media company specializes in user interface, graphical, and visual design research, delivering variations that resonate with your audience on a deeper level, creating lasting connections.
                    </p>
                    <div className="formGroup news m-auto">
                        <input type="email" value={registerData.email4} source='Research' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                        <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                    </div>
                </div>
            </section>

            <section className='home-sec-7 sec-color py-5 my-5 over' id='bg'>
                <div className="container text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <Marquee direction="right" speed={100} delay={5}>
                        <div className="image_wrapper">
                            <img src={`assets/img/Develop/1.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Develop/2.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Develop/3.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Develop/4.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Develop/5.webp`} alt="" className='w-100' />
                        </div>
                        <div className="image_wrapper">
                            <img src={`assets/img/Develop/6.webp`} alt="" className='w-100' />
                        </div>
                    </Marquee>
                    <h1 className="text-white fw-bold fs-85 t-shadow mt-4 mb-0" id='text'>Development</h1>
                    <hr className="back-blue w-50 rounded-1 my-3 m-auto" style={{ "height": "6px" }} />
                    <p className='w m-auto text-white mb-4' id='text'>
                        Crafting digital dreams into reality, our immersive media company specializes in game development, software engineering, and augmented/virtual reality. Explore variations that transport users to extraordinary realms and push the limits of digital experiences.
                    </p>
                    <div className="formGroup news m-auto">
                        <input type="email" value={registerData.email5} source='Development' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                        <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                    </div>
                </div>
            </section >

            <section className='home-sec-5 my-5 over'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h3 className='text-white' id='text'>What Our Client Said About Us</h3>
                    <p className='text-white' id='text'>Use This Section To Describe Your Company</p>
                    <TestCard />
                </div>
            </section>

            <section className="home-sec-4 sec-color py-4 my-5 over" id='bgg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className='text-white fs-80 fw-bolder t-shadow mb-4'>Latest Projects</h1>
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-12 hidden">
                            <div class="card mb-3 border-0">
                                <img src={`assets/img/Updates/1.webp`} class="card-img-top w-100 back-purple w-100" alt="Up-1" />
                                <div class="card-body position-absolute px-5 pb-3 rounded-5" style={{ "backgroundColor": "#3c0089", "bottom": "0px" }}>
                                    <div className="text-button d-flex align-items-center gap-5">
                                        <div className="text">
                                            <h5 class="card-title text-white">“Modern Urban Oasis”</h5>
                                            <p class="card-text text-white jt">Create a luxurious and contemporary urban oasis for a high-end penthouse apartment, complete with sleek furniture, statement art pieces, and panoramic city views.</p>
                                        </div>
                                        <FontAwesomeIcon icon={faArrowRight} className='fs-4 text-white' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div class="card mb-3 border-0">
                                        <img src={`assets/img/Updates/2.webp`} class="card-img-top w-100 back-purple w-100" alt="Up-2" />
                                        <div class="card-body position-absolute px-5 pb-3 rounded-5" style={{ "backgroundColor": "#3c0089", "bottom": "0px" }}>
                                            <div className="text-button d-flex align-items-center gap-5">
                                                <div className="text">
                                                    <p class="card-title text-white">3D Rendered Dreams</p>
                                                    <h5 class="card-text text-white">“Tech-Infused Smart Home”</h5>
                                                </div>
                                                <FontAwesomeIcon icon={faArrowRight} className='fs-4 text-white' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div class="card mb-3 border-0">
                                        <img src={`assets/img/Updates/3.webp`} class="card-img-top w-100 back-purple w-100" alt="Up-3" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div class="card mb-3 border-0">
                                        <img src={`assets/img/Updates/4.webp`} class="card-img-top w-100 back-purple w-100" alt="Up-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-sec-3 sect-color py-4 my-5 over" id='bg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className='text-white fs-85 fw-bolder t-shadow mb-4' id='text'>Our Mission Is To Totally Revolutionize Home Design.</h1>
                    <div className="row align-items-center mb-4">
                        <div className="col-lg-4 col-md-6 my-4">
                            <div className="card card-color">
                                <div className="card-body border-0">
                                    <img src={`assets/img/tabs/1.webp`} alt="tab-1" className='card-img mb-3' />
                                    <h5 className="card-title text-white mb-3">Proprietary 3-Step Process: The Future of Design</h5>
                                    <h6 className="card-subtitle mb-2 text-white">interiorVerse's proprietary 3-step process combines 3DS Max and Unity to curate mind-blowing 3D walkthroughs. Get ready to revolutionize your interior design experience!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-4">
                            <div className="card bg-transparent border-0">
                                <div className="card-body">
                                    <img src={`assets/img/tabs/2.webp`} alt="tab-1" className='card-img-1 mb-3' />
                                    <h5 className="card-title text-white mb-3" id='text'>Interior Design Meets Cutting-Edge VR Technology</h5>
                                    <h6 className="card-subtitle mb-2 text-white" id='text'>interiorVerse is where advanced VR technology transforms interior design into an exhilarating adventure. Explore your creations with the power of virtual reality!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-4">
                            <div className="card bg-transparent border-0">
                                <div className="card-body">
                                    <img src={`assets/img/tabs/3.webp`} alt="tab-1" className='card-img-1 mb-3' />
                                    <h5 className="card-title text-white mb-3" id='text'>interioXr Innovations: Beyond interiorVerse</h5>
                                    <h6 className="card-subtitle mb-2 text-white" id='text'>interioXr ventures beyond interiorVerse, pioneering innovations in AR, VR, and MR. From translation to dining and teaching, we redefine reality across industries!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card bg-transparent border-0">
                                <div className="card-body">
                                    <img src={`assets/img/tabs/4.webp`} alt="tab-1" className='card-img-1 mb-3' />
                                    <h5 className="card-title text-white mb-3" id='text'>interiorVerse: Your VR Design Oasis</h5>
                                    <h6 className="card-subtitle mb-2 text-white" id='text'>Calling all architects and designers - interiorVerse Alpha is your ticket to an extraordinary design experience. Don't miss out on the future of VR walkthroughs!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card bg-transparent border-0">
                                <div className="card-body">
                                    <img src={`assets/img/tabs/5.webp`} alt="tab-1" className='card-img-1 mb-3' />
                                    <h5 className="card-title text-white mb-3" id='text'>Explore the interiorVerse Alpha: Your VR Adventure Begins</h5>
                                    <h6 className="card-subtitle mb-2 text-white" id='text'>Embrace your inner hero and embark on a VR adventure like no other. interiorVerse Alpha is your gateway to creating and experiencing extraordinary interiors!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card bg-transparent border-0">
                                <div className="card-body">
                                    <img src={`assets/img/tabs/6.webp`} alt="tab-1" className='card-img-1 mb-3' />
                                    <h5 className="card-title text-white mb-3" id='text'>Ready to Take the VR Plunge?</h5>
                                    <h6 className="card-subtitle mb-2 text-white" id='text'>The future of interior design is here! Suit up and dive into the VR evolution with interiorVerse Alpha. Your dream spaces await - let's explore!</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1 className='text-white fs-80 fw-bolder t-shadow mb-4' id='text'>Recent Blogs</h1>
                    <div className="row align-items-center mb-3">
                        <div className="col-lg-4 col-md-6 my-4">
                            <div className="card blog">
                                <img src={`assets/img/blog/1.webp`} className="card-img-top w-100" alt="blog-1" />
                                <div className="card-body">
                                    <h5 className="card-title fw-bold mb-3 c-lblue">"Revolutionizing Interior Design: The Rise of interiorVerse VR Walkthroughs"</h5>
                                    <span className="bg-light rounded-pill px-2 py-1 c-blue">Technical</span>
                                    <p className="card-text mt-3 fw-bold jt c-lblue">Explore how interiorVerse is transforming the interior design landscape with its cutting-edge VR walkthroughs. Learn how designers and clients can visualize dream spaces in stunning 3D before they become a reality.</p>
                                    <div className="text-end">
                                        <Link to='/'>
                                            <motion.span whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }} className="btn button-blue text-white me-auto">Read More <FontAwesomeIcon icon={faArrowRight} /></motion.span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-4">
                            <div className="card blog">
                                <img src={`assets/img/blog/2.webp`} className="card-img-top w-100" alt="blog-1" />
                                <div className="card-body">
                                    <h5 className="card-title fw-bold mb-3 c-lblue">"Designing in a New Dimension: Unleashing Your Creativity with interiorVerse"</h5>
                                    <span className="bg-light rounded-pill px-2 py-1 c-blue">Technical</span>
                                    <p className="card-text mt-3 fw-bold jt c-lblue">Discover the power of virtual reality in interior design. This blog dives into how interiorVerse empowers architects and designers to explore their creativity and bring their ideas to life like never before.</p>
                                    <div className="text-end">
                                        <Link to='/'>
                                            <motion.span whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }} className="btn button-blue text-white me-auto">Read More <FontAwesomeIcon icon={faArrowRight} /></motion.span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card blog">
                                <img src={`assets/img/blog/3.webp`} className="card-img-top w-100" alt="blog-1" />
                                <div className="card-body">
                                    <h5 className="card-title fw-bold mb-3 c-lblue">"The Future of Design Visualization: Embracing Hyper-Realism with
                                        interiorVerse"</h5>
                                    <span className="bg-light rounded-pill px-2 py-1 c-blue">Technical</span>
                                    <p className="card-text mt-3 fw-bold jt c-lblue">Explore the hyper-realistic world of interiorVerse and its impact
                                        on design visualization. Learn how advanced VR technology is changing the game for architects and
                                        consumers alike.</p>
                                    <div className="text-end">
                                        <Link to='/'>
                                            <motion.span whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }} className="btn button-blue text-white me-auto">Read More <FontAwesomeIcon icon={faArrowRight} /></motion.span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-sec-2 sec-color py-5 my-5 over' id='bgg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className="text-white fs-85 fw-bolder w-75 mb-4 t-shadow ms-auto text-end" >Subscribe To Our Updates</h1>
                    <div className="row align-items-center mb-3" style={{ "marginTop": "-55px" }}>
                        <div className="col-md-7" >
                            <video autoPlay loop muted className='rounded-5 w-100'>
                                <source src={`assets/img/Subs.mp4`} type="video/mp4" />
                            </video>
                        </div>
                        <div className="col-md-5" >
                            <h3 className='text-white pb-2'>Stay in the loop with latest developments as we welcome the future, together.</h3>
                            <Link to='/' className="text-white">
                                <p><FontAwesomeIcon icon={faCirclePlay} /> View Post Newsletters</p>
                            </Link>
                            <div className="formGroup news">
                                <input type="email" value={registerData.email6} source='Newsletter' onChange={handleInput} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                <motion.button whileTap={{ scale: 0.5 }} type="button" onClick={handleSubmission} value="Subscribe" className="SubscribeBtn">Sign Up! <FaLink /> </motion.button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <Link to='https://www.facebook.com/interioxr'><button className='btn face fs-3 text-primary pb-3'><FaFacebook /></button></Link>
            <Link to='https://api.whatsapp.com/send/?phone=919560013324&text&type=phone_number&app_absent=0' target='_blank'><button className='btn whats fs-4 text-success pb-3'><FaWhatsapp /></button></Link>
            <Link to='https://www.linkedin.com/company/interioxr/'><button className='btn link fs-3 text-primary pb-3'><FaLinkedin /></button></Link>
            <Link to='https://www.instagram.com/interioxr/' target='_blank'><button className='btn insta fs-3 text-danger pb-3'><FaInstagram /></button></Link>
            <Link to='https://twitter.com/InterioXr'><button className='btn twit fs-3 text-primary pb-3'><FaTwitter /></button></Link>
            <Link to='https://discord.com/invite/JJNZr6Hkdh' target='_blank'><button className='btn discord fs-3 text-white pb-3'><FaDiscord /></button></Link>

            <Backtotop />
            <FloatingWhatsApp
                phoneNumber="918826144224"
                accountName="interior"
                allowEsc
                allowClickAway
                notification
                statusMessage='online'
                avatar={`assets/img/avatar.webp`}
                darkMode
                notificationSound
            />
        </>
    )
}

export default Home;
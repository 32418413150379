import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faInfoCircle, faHandHoldingHeart, faDownload, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { ThemeContext } from '../App';


const Header = () => {
    const { toggleTheme, theme } = useContext(ThemeContext);
    
    return (
        <header className='fixed-top'>
            <nav className="navbar navbar-expand-lg rounded-5 mb-5">
                <div className="container-fluid">
                    <div className="logo-text d-flex align-items-center pe-5">
                        <motion.div className="logo" initial={{ scale: 0 }}
                            animate={{ rotate: 360, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}>
                            <Link classname="navbar-brand" exact to="/" ><img src={`assets/img/Logo.webp`} alt="logo" /></Link>
                        </motion.div>
                        <div className="text">
                            <p className="text-white fw-bold fs-4 mb-0">interiorVerse</p>
                            <p className="text-white fw-bold fs-10 mb-0">by interioXr Technologies</p>
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                            <li className="nav-item me-2" >
                                <NavLink className="nav-link px-3" exact to='/'>
                                    <FontAwesomeIcon icon={faHouse} /> Home</NavLink>
                            </li>
                            <li className="nav-item me-2">
                                <NavLink className="nav-link px-3" exact to='/why-us'>
                                    <FontAwesomeIcon icon={faInfoCircle} /> Why Us?</NavLink>
                            </li>
                            <li className="nav-item me-2">
                                <Link className="nav-link px-3" exact to='https://www.interioxr.com/' target="_blank">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> interioXr</Link>
                            </li>
                            <li className="nav-item me-2">
                                <Link className="nav-link px-3" exact to='https://interiorverse.interioxr.com/webapp' target="_blank">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> WebApp</Link>
                            </li>
                            <li className="nav-item me-2">
                                <NavLink className="nav-link px-3" exact to='/download'>
                                <FontAwesomeIcon icon={faDownload} /> Download</NavLink>
                            </li>
                            <li className="nav-item me-2">
                                <NavLink className="nav-link px-3" exact to='/contact'>
                                    <FontAwesomeIcon icon={faHandHoldingHeart} /> Contact Us</NavLink>
                            </li>
                            <div className="checkbox-wrapper-54">
                                <label className="switch">
                                    <input type="checkbox" onChange={toggleTheme} checked={theme === "dark"} />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Header
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import Why from './pages/Why';
import Header from './Components/Header';
import { createContext, useState } from "react";
import Footer from './Components/Footer';
import Download from './pages/Download';
import ReactGA from 'react-ga';
export const ThemeContext = createContext(null);



function App() {

  ReactGA.initialize('G-RE55388QPZ');

  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div id={theme}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route>
              <Route path='/' element={<Home />} />
              <Route path='/why-us' element={<Why />}></Route>
              <Route path='/contact' element={<Contact />}></Route>
              <Route path='/download' element={<Download />}></Route>
              <Route path='/*' element={<Navigate to='/' />}></Route>
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;

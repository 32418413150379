import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { FaDiscord, FaEnvelope, FaPhoneAlt, FaWhatsapp, FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaLink } from "react-icons/fa";
import axios from 'axios';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        setEmail(e.target.value)
    }

    const enquiryurl = `https://admin.interioxr.com/backend`;

    const handleApi = async () => {
        const url = enquiryurl || 'http://localhost:3001';
        console.log('URL:', `${url}/api/v1/enquiry`);
        await axios.post(`${url}/api/v1/enquiry`, {
            "email": email,
            "source": "interiorverse.interioxr.com:Contact"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(result => {
                alert("Thank you for your interest. We will reach out to you shortly.");
                console.log(result);
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <section className='sec-cont-1 sec-color py-5 mt-6' id='bgg'>
                <div className="container-fluid" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <img src={`assets/img/Back1.webp`} alt="" className='rounded-5 w-100' />
                        </div>
                        <div className="col-md-5" style={{ "marginLeft": "-40px" }}>
                            <div className="bg-center " style={{ "padding": "140px 40px " }}>
                                <h1 className='text-white fw-bolder w-75 mb-5'>REACH OUT TO US AT : //</h1>
                                <ul class="list-group">
                                    <Link onClick={() => window.location = 'mailto: info@interioXr.com'}><li class="list-group-item border-0 bg-transparent text-white pb-1 fs-4"><FaEnvelope /> info@interioxr.com</li></Link>
                                    <Link onClick={() => window.location = 'tel: +918826144224'} ><li class="list-group-item border-0 bg-transparent text-white fs-4 pb-1"><FaPhoneAlt /> +918826144224</li></Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec-cont-2 my-5" >
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row">
                        <div className="col-md-7 offset-md-5 p-3 sec-color " id='bgg'>
                            <h1 className='text-white fw-bolder'>OR SCHEDULE A WORD!</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-3 my-5' id='contact'>
                <div className="container bord p-3 bg-center sec-color" id='bgg' data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h4 className='jt text-white mb-4 text-center'><u>Keen On Partnering Up</u></h4>
                    <div className="row align-items-center justify-content-around">
                        <div className="col-md-5 my-3">
                            <h5 className='text-white text-center mb-0'>Start a Conversation with Us</h5>
                        </div>
                        <div className="col-md-7 my-3">
                            <div className="formGroup news">
                                <input type="email" value={email} onChange={handleSubmit} placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn" onClick={handleApi}>Sign Up! <FaLink /> </motion.button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Link to='https://www.facebook.com/interioxr'><button className='btn face fs-3 text-primary pb-3'><FaFacebook /></button></Link>
            <Link to='https://api.whatsapp.com/send/?phone=919560013324&text&type=phone_number&app_absent=0' target='_blank'><button className='btn whats fs-4 text-success pb-3'><FaWhatsapp /></button></Link>
            <Link to='https://www.linkedin.com/company/interioxr/'><button className='btn link fs-3 text-primary pb-3'><FaLinkedin /></button></Link>
            <Link to='https://www.instagram.com/interioxr/' target='_blank'><button className='btn insta fs-3 text-danger pb-3'><FaInstagram /></button></Link>
            <Link to='https://twitter.com/InterioXr'><button className='btn twit fs-3 text-primary pb-3'><FaTwitter /></button></Link>
            <Link to='https://discord.com/invite/JJNZr6Hkdh' target='_blank'><button className='btn discord fs-3 text-white pb-3'><FaDiscord /></button></Link>
        </>
    )
}

export default Contact
import React, {useState} from 'react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import axios from 'axios';

const Footer = () => {

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        setEmail(e.target.value)
    }

    const enquiryurl = `https://admin.interioxr.com`;

    const handleApi = async () => {
        const url = enquiryurl || 'http://localhost:3001';
        console.log('URL:', `${url}/api/v1/enquiry`);
        await axios.post(`${url}/api/v1/enquiry`, {
            "email": email,
            "source": "interiorverse.interioxr.com:Footer"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(result => {
                alert("Thank you for your interest. We will reach out to you shortly.");
                console.log(result);
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <footer className='bg-light pt-3 rounded-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 my-2">
                            <div className="logo-text d-flex align-items-center pe-5">
                                <motion.div className="logo" initial={{ scale: 0 }}
                                    animate={{ rotate: 360, scale: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20
                                    }}>
                                    <Link classname="navbar-brand" exact to="/" ><img src={`assets/img/Logo.webp`} alt="logo" /></Link>
                                </motion.div>
                                <div className="text">
                                    <p className="c-lblue fw-bold fs-3 mb-0">interiorVerse</p>
                                    <p className="c-lblue fw-bold mb-0 fs-10">by interioXr Technologies</p>
                                </div>
                            </div>
                            <p className='mt-2 jt'>Welcome to interiorVerse, where design dreams come to life in breathtaking virtual
                                reality! Step into a world of hyper-realistic and fully immersive VR walkthroughs that redefine how we envision and create interior spaces. Welcome to interiorVerse – where your dream spaces await!</p>
                        </div>
                        <div className="col-lg-3 col-md-6 py-3 my-2">
                            <h5 className='fw-bold mb-3'>Get in Touch</h5>
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent border-0 pb-3"><FontAwesomeIcon icon={faLocationDot} /> : DLF Phase IV, Gurugram </li>
                                <li class="list-group-item bg-transparent border-0 pb-3">
                                    <Link onClick={() => window.location = 'mailto: info@interioXr.com'} className='text-dark'><FontAwesomeIcon icon={faEnvelope} /> : info@interioXr.com</Link>
                                </li>
                                <li class="list-group-item bg-transparent border-0 pb-3">
                                    <Link onClick={() => window.location = 'tel: +918826144224'} className='text-dark'><FontAwesomeIcon icon={faPhone} /> : +91 8826144224 </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 py-3 my-2">
                            <h5 className='fw-bold mb-3'>Recent Posts</h5>
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent border-0 pb-3 pe-1 ps-0">
                                    <div className="post d-flex align-items-center gap-2">
                                        <img src={`assets/img/Footer/1.webp`} alt="Post-1" className='w-50' />
                                        <p className='jt'>Designing in a New Dimension: Unleashing Your Creativity with interiorVerse</p>
                                    </div>
                                </li>
                                <li class="list-group-item bg-transparent border-0 pb-3 ps-0 pe-1">
                                    <div className="post d-flex align-items-center gap-2">
                                        <img src={`assets/img/Footer/2.webp`} alt="Post-2" className='w-50' />
                                        <p className='jt'>The Future of Design Visualization: Embracing Hyper-Realism with interiorVerse</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 py-3 my-2">
                            <h5 className='fw-bold mb-5'>Join Our Newsletter</h5>
                            <form action="" className='footer-form'>
                                <div class="mb-3">
                                    <label for="email" class="form-label ms-3 mb-1 c-blue">Your Email</label>
                                    <input type="email" value={email} onChange={handleSubmit} class="form-control rounded-4 mb-3" id="email" aria-describedby="emailHelp" placeholder='Enter Your Email' />
                                    <div className="text-center">
                                        <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn" onClick={handleApi} >Subscribe </motion.button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer